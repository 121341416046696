import { NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../slices/usersApiSlice';
import { logout } from '../slices/authSlice';

const Header = () => {
  const { userInfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logoutApiCall] = useLogoutMutation();

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <header id="main-header">
        <div className="container">
            <h1><span className="logo"><i className="fa-solid fa-mountain-sun"></i>Sugarloaf</span>Homestead</h1>
        </div>
        { userInfo ? (
                <NavDropdown title={userInfo.username} id='username'>
                  <LinkContainer to='/dashboard'>
                    <NavDropdown.Item>
                      Dashboard
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/dashboard/profile'>
                    <NavDropdown.Item>
                      Profile
                    </NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <div></div>
              )}
    </header>
  )
}

export default Header