import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useGetRecipesQuery } from '../slices/recipesSlice';
import Loader from '../components/Loader';
import Message from '../components/Message';

const RecipesScreen = () => {
  const { data: recipes, isLoading, error } = useGetRecipesQuery();

  return (
    <>
      { isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{ error?.data?.message || error.error }</Message>
      ) : (
        <>
          <h1>Recipes</h1>
          {recipes.map((category) => (
            <div key={category[0]._id}>
              <h4 className="my-2">{category[0].category}</h4>
              {category.map((recipe) => (
                <Row key={recipe._id} className="my-1">
                  <Link className="recipe" to={`/recipes/${recipe.servername}`}>
                    {recipe.name}
                  </Link>
                </Row>
              ))}
            </div>
          ))}
        </>
      )}
    </>
  )
}

export default RecipesScreen
