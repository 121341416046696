import { useState } from "react";
import { Form, Button, Image } from 'react-bootstrap';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { useAddPhotoMutation, useUploadImageMutation } from "../../slices/photosSlice";
import DashboardNav from "../../components/DashboardNav"

const PhotosAddScreen = () => {
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [image, setImage] = useState('');
  const [description, setDescription] = useState('');

  const [addPhoto, { isLoading }] = useAddPhotoMutation();

  const [uploadImage, { isLoading: loadingImage }] = useUploadImageMutation();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await addPhoto({ 
        title, 
        category, 
        image,
        description
       }).unwrap();
      toast.success('Photo added');
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  }

const uploadFileHandler = async (e) => {
  const formData = new FormData();
  formData.append('image', e.target.files[0]);
  try {
    const res = await uploadImage(formData).unwrap();
    toast.success(res.message);
    setImage(res.image);
  } catch (error) {
    toast.error(error?.data?.message || error.error);
  }
}

return (
  <>
    <DashboardNav />
    <h2>Add New Photo</h2>
        <Form.Group controlId="title" className="my-2">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type='text'
            placeholder="Enter title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}>
          </Form.Control>
        </Form.Group>
        
        <Form.Group controlId="category" className="my-2">
          <Form.Label>Category</Form.Label>
          <Form.Control
            type='text'
            placeholder="Enter category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="image" className="my-2">
          <Form.Label>Image</Form.Label>
          <Form.Control 
            type='text'
            placeholder='Enter image URL'
            value={image}
            onChange={(e) => setImage}>
          </Form.Control>
          <Form.Control
            type='file'
            label='Choose file'
            onChange={uploadFileHandler}>
          </Form.Control>
          {loadingImage && <Loader />}
        </Form.Group>

        {image && <Image className="photo" src={image} alt='No image yet' fluid />}

        <Form.Group controlId="description" className="my-2">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type='text'
            placeholder="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}>
          </Form.Control>
        </Form.Group>

        <Button onClick={submitHandler} variant="primary" className="my-2">
          Add Photo
        </Button>
        { isLoading && <Loader />}
    </>
  )
}

export default PhotosAddScreen
