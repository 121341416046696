import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { useGetVideoDetailsQuery, useUpdateVideoMutation } from "../../slices/videosSlice";
import DashboardNav from "../../components/DashboardNav";
import { toast } from "react-toastify";

const VideoEditScreen = () => {
  const { id: videoId } = useParams();

  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [video, setVideo] = useState('');
  const [description, setDescription] = useState('');

  const { data: videoData, isLoading, error } = useGetVideoDetailsQuery(videoId);

  const [updateVideo, { isLoading: loadingUpdate }] = useUpdateVideoMutation();

  const navigate = useNavigate();

  useEffect(() => { 
    if (videoData) {
      setTitle(videoData.title);
      setCategory(videoData.category);
      setVideo(videoData.video);
      setDescription(videoData.description);
    }
   }, [videoData]);

  const submitHandler = async (e) => {
    e.preventDefault();
    const updatedVideo = { 
      _id: videoId,
      title,
      category,
      video,
      description
    }

    const result = await updateVideo(updatedVideo);
    if (result.error) {
      toast.error(result.error);
    } else {
      toast.success('Video updated');
      navigate('/dashboard/videos');
    }
  }

  return (
    <>
      <DashboardNav />
      <h2>Update Video</h2>
      {loadingUpdate && <Loader />}

      {isLoading ? <Loader /> : 
      error ? <Message variant='danger'>{error}</Message> : (
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="title" className="my-2">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type='text'
            placeholder="Enter title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="category" className="my-2">
          <Form.Label>Category</Form.Label>
          <Form.Control
            type='text'
            placeholder="Enter category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="video" className="my-2">
          <Form.Label>Video</Form.Label>
          <Form.Control
            type='text'
            placeholder="video"
            value={video}
            onChange={(e) => setVideo(e.target.value)}>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="description" className="my-2">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type='text'
            placeholder="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}>
          </Form.Control>
        </Form.Group>
        <Button type="submit" variant="primary" className="my-2">
          Update Video
        </Button>
      </Form>
    )}
    </>
  )
}

export default VideoEditScreen
