import { Image } from 'react-bootstrap';
import { useGetDisplayPhotosQuery } from '../slices/photosSlice';
import Loader from '../components/Loader';
import Message from '../components/Message';

const GalleryScreen = () => {
  const { data: photos, isLoading, error } = useGetDisplayPhotosQuery();

  return (
    <div className="container">
      <section id="main">
        <h1>Gallery</h1>
        { isLoading ? (
          <Loader />
          ) : error ? (
          <Message variant='danger'>{ error?.data?.message || error.error }</Message>
          ) : (
          <>
            {photos.map((category) => (
              <div key={category[0]._id}>
                <h3>{category[0].category}</h3>
                <div className="photos">
                  {category.map((photo) => (
                    <div key={photo._id}>
                      <Image className="photo" src={photo.image} alt={photo.title} fluid />
                      <h4 id='photo-title'>{photo.title}</h4>
                      <p id='photo-desc'>{photo.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </>
        )}
      </section>
    </div>
  )
}

export default GalleryScreen
