import { useState } from "react";
import { Form, Button } from 'react-bootstrap';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { useAddVideoMutation } from '../../slices/videosSlice';
import DashboardNav from "../../components/DashboardNav"

const VideoAddScreen = () => {
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [video, setVideo] = useState('');
  const [description, setDescription] = useState('');

  const [addVideo, { isLoading }] = useAddVideoMutation();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await addVideo({ 
        title, 
        category,  
        video, 
        description
       }).unwrap();
      toast.success('Video added');
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  }

  return (
    <>
      <DashboardNav />
      <h2>Add New Video</h2>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="title" className="my-2">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type='text'
            placeholder="Enter title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="category" className="my-2">
          <Form.Label>Category</Form.Label>
          <Form.Control
            type='text'
            placeholder="Enter category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="video" className="my-2">
          <Form.Label>Video</Form.Label>
          <Form.Control
            type='text'
            placeholder="video"
            value={video}
            onChange={(e) => setVideo(e.target.value)}>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="description" className="my-2">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type='text'
            placeholder="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}>
          </Form.Control>
        </Form.Group>
        <Button type="submit" variant="primary" className="my-2">
          Add Video
        </Button>
        { isLoading && <Loader />}
      </Form>
    </>
  )
}

export default VideoAddScreen
