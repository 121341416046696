import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import { useGetPhotoDetailsQuery, useUpdatePhotoMutation, useUploadImageMutation } from "../../slices/photosSlice";
import DashboardNav from "../../components/DashboardNav";
import Message from "../../components/Message";

const PhotosEditScreen = () => {
  const { id: photoId } = useParams();

  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [image, setImage] = useState('');
  const [description, setDescription] = useState('');

  const { data: photoData, isLoading, error } = useGetPhotoDetailsQuery(photoId);

  const [updatePhoto, { isLoading: loadingUpdate }] = useUpdatePhotoMutation();

  const [uploadImage, { isLoading: loadingImage }] = useUploadImageMutation();

  const navigate = useNavigate();

  useEffect(() => { 
    if (photoData) {
      setTitle(photoData.title);
      setCategory(photoData.category);
      setImage(photoData.image);
      setDescription(photoData.description);
    }
   }, [photoData]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const updatedPhoto = {
        _id: photoId,
        title,
        category,
        image,
        description
      }
      await updatePhoto(updatedPhoto);
      toast.success('Photo updated');
      navigate('/dashboard/gallery');
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  }

const uploadFileHandler = async (e) => {
  const formData = new FormData();
  formData.append('image', e.target.files[0]);
  try {
    const res = await uploadImage(formData).unwrap();
    toast.success(res.message);
    setImage(res.image);
  } catch (error) {
    toast.error(error?.data?.message || error.error);
  }
}

return (
  <>
    <DashboardNav />
    <h2>Update Photo</h2>
      {isLoading ? <Loader /> : 
      error ? <Message variant='danger'></Message> : (
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="title" className="my-2">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type='text'
            placeholder="Enter title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}>
          </Form.Control>
        </Form.Group>
        
        <Form.Group controlId="category" className="my-2">
          <Form.Label>Category</Form.Label>
          <Form.Control
            type='text'
            placeholder="Enter category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="image" className="my-2">
          <Form.Label>Image</Form.Label>
          <Form.Control 
            type='text'
            placeholder='Enter image URL'
            value={image}
            onChange={(e) => setImage}>
          </Form.Control>
          <Form.Control
            type='file'
            label='Choose file'
            onChange={uploadFileHandler}>
          </Form.Control>
          {loadingImage && <Loader />}
        </Form.Group>

        <Form.Group controlId="description" className="my-2">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type='text'
            placeholder="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}>
          </Form.Control>
        </Form.Group>

        <Button type="submit" variant="primary" className="my-2">
          Update Photo
        </Button>
        { loadingUpdate && <Loader />}
      </Form>
      )}
    </>
  )
}

export default PhotosEditScreen
