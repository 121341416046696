const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer id="main-footer">
        <p>Copyright &copy;{currentYear} Sugarloaf Homestead</p>
    </footer>
  )
}

export default Footer

