const Showcase = () => {
  return (
    <section id="showcase">
      <div className="container">
          <h1>Urban homesteading in the heart of Las Vegas</h1>
      </div>
    </section>
  )
}

export default Showcase
