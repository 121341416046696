import React from 'react'

const ContactScreen = () => {
  return (
    <div className="container">
        <section id="main">
            <h1>Contact Us</h1>
            <p>David France</p>
            <p>davidfrance475@gmail.com</p>
        </section>

        <aside id="sidebar">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum dolores maiores deleniti nostrum et aliquam vitae dolor. Cumque dolores fugit, iusto ea nobis perferen
        </aside>  
    </div>
  )
}

export default ContactScreen
