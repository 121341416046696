import { VIDEOS_URL } from "../constants";
import { apiSlice } from "./apiSlice";

const videosApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVideos: builder.query({
      query: () => ({
        url: VIDEOS_URL,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['Videos']
    }),
    getLatestVideos: builder.query({
      query: () => ({
        url: `${VIDEOS_URL}/latest`,
      }),
      keepUnusedDataFor: 5,
    }),
    getVideoDetails: builder.query({
      query: (videoId) => ({
        url: `${VIDEOS_URL}/${videoId}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['Video'],
    }),
    addVideo: builder.mutation({
      query: (data) => ({
        url: VIDEOS_URL,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Video'],
    }),
    deleteVideo: builder.mutation({
      query: (videoId) => ({
        url: `${VIDEOS_URL}/${videoId}`,
        method: 'DELETE',
      })
    }),
    updateVideo: builder.mutation({
      query: (data) => ({
        url: `${VIDEOS_URL}/${data._id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Videos'],
    })
  }),
});

export const { useGetVideosQuery, useGetVideoDetailsQuery, useAddVideoMutation, useDeleteVideoMutation, useUpdateVideoMutation, useGetLatestVideosQuery } = videosApiSlice;