import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Row, Col } from "react-bootstrap";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { useGetRecipesQuery, useDeleteRecipeMutation } from "../../slices/recipesSlice";
import { toast } from 'react-toastify';
import DashboardNav from "../../components/DashboardNav";

const RecipesListScreen = () => {
  const { data: recipes, refetch, isLoading, error } = useGetRecipesQuery();

  const [deleteRecipe, { isLoading: loadingDelete }] = useDeleteRecipeMutation();

  const deleteHandler = async (id) => {
    if (window.confirm('Delete recipe?')) {
      try {
        await deleteRecipe(id);
        refetch();
        toast.success('Recipe deleted');
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  }

  return (
    <>
      <DashboardNav />
      <Row className="align-items-center">
        <Col>
          <h1>Recipes</h1>
        </Col>
        <Col>
          <LinkContainer to='/dashboard/recipes/addrecipe'>
            <Button variant="success">
              <FaPlus /> Add Recipe
            </Button>
          </LinkContainer>
        </Col>
      </Row>

      {loadingDelete && <Loader />}
      { isLoading ? <Loader /> : error ? <Message variant="danger"></Message> : (
        <>
          <Table striped hover responsive className="table-sm">
            <thead>
              <tr>
                <th>NAME</th>
                <th>CATEGORY</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {recipes.map((category) => (
                category.map((recipe) => (
                  <tr key={recipe._id}>
                    <td>{recipe.name}</td>
                    <td>{recipe.category}</td>
                    <td>
                      <LinkContainer to={`/dashboard/recipes/edit/${recipe.servername}`}>
                        <Button variant="light" className="btn-sm mx-2">
                          <FaEdit /> 
                        </Button>
                      </LinkContainer>
                      <Button variant="danger" className="btn-sm" onClick={() => deleteHandler(recipe._id)}>
                        <FaTrash style={{color: 'white'}}/>
                      </Button>
                    </td>
                  </tr>
                ))
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  )
}

export default RecipesListScreen
