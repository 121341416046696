import { useState, useEffect } from "react"
import { Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setCredentials } from "../slices/authSlice";
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import { useProfileMutation } from '../slices/usersApiSlice';
import DashboardNav from "../components/DashboardNav";

const ProfileScreen = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.auth);

  const [updateProfile, { isLoading: loadingUpdateProfile}] = useProfileMutation();

  useEffect(() => {
    if (userInfo) {
      setUsername(userInfo.username);
    }
  }, [userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
    } else {
      try {
        const res = await updateProfile({ _id: userInfo._id, username, password }).unwrap();
        dispatch(setCredentials(res));
        toast.success('Profile updated');
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  }

  return (
    <>
      <DashboardNav />
      <h2>User Profile</h2>

      <Form onSubmit={submitHandler}>
        <Form.Group controlId="username" className="my-2">
          <Form.Label>Update Username</Form.Label>
          <Form.Control
            type='name'
            placeholder="Enter username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="password" className="my-2">
          <Form.Label>Update Password</Form.Label>
          <Form.Control
            type='password'
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="confirm-password" className="my-2">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type='password'
            placeholder="Confirm password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}>
          </Form.Control>
        </Form.Group>

        <Button type="submit" variant="primary" className="my-2">
          Update
        </Button>
        { loadingUpdateProfile && <Loader />}
      </Form>
    </>
  )
}

export default ProfileScreen
