import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../slices/usersApiSlice';
import { logout } from '../slices/authSlice';

const DashboardNav = () => {
  const { userInfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logoutApiCall] = useLogoutMutation();

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <h3>Welcome {userInfo.username}</h3>
      <Navbar id="dash-navbar" expand='md collapseOnSelect'>
          <Container>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse id='basic-navbar-nav'>
              <Nav className='md-auto'>
                <LinkContainer to='/dashboard/profile'>
                  <Nav.Link className='dash-nav'>
                    Profile
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to='/dashboard/posts'>
                  <Nav.Link className='dash-nav'>
                    Posts
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to='/dashboard/videos'>
                  <Nav.Link className='dash-nav'>
                    Videos
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to='/dashboard/gallery'>
                  <Nav.Link className='dash-nav'>
                    Pictures
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to='/dashboard/recipes'>
                  <Nav.Link className='dash-nav'>
                    Recipes
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to='/dashboard/10644'>
                  <Nav.Link className='dash-nav' onClick={logoutHandler}>
                    Logout
                  </Nav.Link>
                </LinkContainer>
              </Nav>
            </Navbar.Collapse>
          </Container>
      </Navbar>
    </>
  )
}

export default DashboardNav
