import { Image, Carousel } from "react-bootstrap";
import Loader from "./Loader";
import Message from "./Message";
import { useGetRecentPhotosQuery } from "../slices/photosSlice";

const PhotoCarousel = () => {
  const { data: photos, isLoading, error } = useGetRecentPhotosQuery();

  return isLoading ? <Loader /> : error ? <Message variant='danger'>{error}</Message> : (
    <Carousel pause='hover'>
      {photos.map(photo => (
        <Carousel.Item key={photo._id}>
          <Image src={photo.image} alt={photo.name} fluid />
          <Carousel.Caption className="carousel-caption">
            <h4>{photo.title}</h4>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

export default PhotoCarousel