import { Outlet } from 'react-router-dom';
import DashAuthError from './DashAuthError';
import { useSelector } from 'react-redux';

const PrivateRoute = () => {
  const { userInfo } = useSelector(state => state.auth);

  return userInfo ? <Outlet /> : <DashAuthError />
}

export default PrivateRoute
