import DashboardNav from '../../components/DashboardNav';

const DashboardScreen = () => {

  return (
    <>
      <DashboardNav />
      <h2>Welcome to the Sugarloaf Homestead admin dashboard. Click on a category to add, edit, and delete.</h2>
    </>
  )
}

export default DashboardScreen
