import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

const Navigation = () => {
  return (
    <Navbar id="navbar" expand='md' data-bs-theme='dark' collapseOnSelect>
        <Container>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='md-auto'>
              <LinkContainer to='/'>
                <Nav.Link>
                  Home
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to='/videos'>
                <Nav.Link>
                  Videos
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to='/gallery'>
                <Nav.Link>
                  Gallery
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to='/recipes'>
                <Nav.Link>
                  Recipes
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to='/champlain'>
                <Nav.Link>
                  Champlain Valley
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to='/contact'>
                <Nav.Link>
                  Contact
                </Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
    </Navbar>
  )
}

export default Navigation
