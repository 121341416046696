import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Row, Col } from "react-bootstrap";
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { useGetPostsQuery, useDeletePostMutation } from "../../slices/postsSlice";
import { toast } from 'react-toastify';
import DashboardNav from "../../components/DashboardNav";

const PostListScreen = () => {
  const { data: posts, refetch, isLoading, error } = useGetPostsQuery();

  const [deletePost, { isLoading: loadingDelete }] = useDeletePostMutation();

  const deleteHandler = async (id) => {
    if (window.confirm('Delete post?')) {
      try {
        await deletePost(id);
        refetch();
        toast.success('Post deleted');
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  }

  return (
    <>
      <DashboardNav />
      <Row className="align-items-center">
        <Col>
          <h1>Posts</h1>
        </Col>
        <Col>
          <LinkContainer to='/dashboard/posts/addpost'>
            <Button variant="success">
              <FaPlus /> Add Post
            </Button>
          </LinkContainer>
        </Col>
      </Row>

      { loadingDelete && <Loader /> }
      { isLoading ? <Loader /> : error ? <Message variant="danger"></Message> : (
        <>
          <Table striped hover responsive className="table-sm">
            <thead>
              <tr>
                <th>AUTHOR</th>
                <th>TITLE</th>
                <th>BODY</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {posts.map((post) => (
                <tr key={post._id}>
                  <td>{post.name}</td>
                  <td>{post.title}</td>
                  <td>{post.body}</td>
                  <td>
                    <LinkContainer to={`/dashboard/posts/edit/${post._id}`}>
                      <Button variant="light" className="btn-sm mx-2">
                        <FaEdit /> 
                      </Button>
                    </LinkContainer>
                    <Button variant="danger" className="btn-sm" onClick={() => deleteHandler(post._id)}>
                      <FaTrash style={{color: 'white'}}/>
                    </Button>
                  </td>
                </tr>
              )
              )}
            </tbody>
          </Table>
        </>
      )}
    </>
  )
}

export default PostListScreen


