import { RECIPES_URL } from "../constants";
import { apiSlice } from "./apiSlice";

const recipesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRecipes: builder.query({
      query: () => ({
        url: RECIPES_URL,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['Recipes']
    }),
    getRecipeDetails: builder.query({
      query: (recipeServerName) => ({
        url: `${RECIPES_URL}/${recipeServerName}`,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['Recipe'],
    }),
    addRecipe: builder.mutation({
      query: (data) => ({
        url: RECIPES_URL,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Recipe'],
    }),
    deleteRecipe: builder.mutation({
      query: (recipeId) => ({
        url: `${RECIPES_URL}/${recipeId}`,
        method: 'DELETE',
      })
    }),
    updateRecipe: builder.mutation({
      query: (data) => ({
        url: `${RECIPES_URL}/${data._id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Recipes'],
    })
  }),
});

export const { useGetRecipesQuery, useGetRecipeDetailsQuery, useAddRecipeMutation, useDeleteRecipeMutation, useUpdateRecipeMutation } = recipesApiSlice;