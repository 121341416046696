const ChamplainScreen = () => {
  return (
    <div className="container">
        <section id="main">
            <h1>Future Homestead</h1>
            <p>After finishing our time in Las Vegas, we plan on moving to a more traditional homestead in the Champlain Valley of the Adirondack Mountains. The area is rich in history, including a long line of homesteaders eking out a subsistence from the rocky soil. From the Iroquois Nation’s Great Longhouse, through a variety of European settlers and Robber Barons, the area has hosted a wide range of people trying to make their way through life. We intend to follow in the footsteps of great Americans, such as John Brown and Teddy Roosevelt.</p>
        </section>

        <aside id="sidebar">
            Before heading off to Bleeding Kansas, then onto his famous raid at Harper’s Ferry, John Brown carved out a homestead near Lake Placid where he welcomed free blacks, including runaway slaves. He taught these freemen how to scratch out a life from the rocky soil, showing them how to survive the harsh Adirondack winters and maximize the short growing season.
        </aside>  
    </div>
  )
}

export default ChamplainScreen
